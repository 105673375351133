img.language {
  width: 26px;
  margin-right: 14px;
  margin-top: -10px;
  margin-bottom: -13px;
}

.languageList {
  list-style-type: none;
  display: flex;
  justify-content: center;

  a {
    margin: 10px;
  }

}

.languagePicker, .languageLink {

  cursor: pointer;

  //border: 2px solid #ccc;
  background-color: #fff;
  display: inline-block;
  padding-left: 0;

  font-family: "Klima", "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 16pt;
  //text-transform: uppercase;
  text-decoration: none;

  color: #003E78;

  &:hover,
  &:focus,
  &.activeLanguage {
    text-decoration: none !important;
    color: #E94E77;
  }

}
